<template>
  <div class="flex">
    <TempLeftNav />
    <div class="content">
      <div class="page">
        <section class="content-band pagehead">
          <div class="pagehead-title">
            <h1>Modal</h1>
            <div class="pagehead-link">
              <a href=""
                ><img src="/html-components/images/svg/link.svg" alt=""
              /></a>
            </div>
          </div>
          <p>
            A modal is an object that overlays the center of the page with a
            screen overlay behind it, screening the content below.
          </p>
        </section>

        <div class="divider_lrg"></div>

        <section class="content-band" id="general-guidelines">
          <h2>General Guidelines</h2>
          <ul class="guidelines">
            <li>
              A modal should only be used when user input is critical, such that
              the user cannot continue without interacting with it. Confirming
              the deletion of data, entering a multi-factor authentication or
              password reset PIN are suitable examples.
            </li>
            <li>
              A modal should not be used for email subscription pop-up.
            </li>
          </ul>
          <div class="call-out">
            <div class="call-out-title semibold">
              Simple Standard
            </div>
            <p>
              To decide whether a modal is appropriate, use the following
              standard: If you were working as waitstaff at a restaurant and a
              pair of individuals have been seated at one of your tables. The
              individuals are engaged in a deep discussion. Consider what would
              be of sufficient importance for you to interrupt them.
            </p>
          </div>
        </section>

        <section class="content-band" id="modal">
          <CodeView :component="primary.main">
            <ButtonComponent type="primary" text="Button" />
          </CodeView>
        </section>
        <div class="divider_lrg"></div>

        <!-- <div class="content-menu"></div> -->
      </div>

      <div class="footer-container"></div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "../ButtonComponent";
import CodeView from "../CodeView";
import TempLeftNav from "../TempLeftNav";
export default {
  name: "Modal",
  data: function() {
    return {
      primary: {
        main: {
          codeHTML:
            "<button id='button-js--primary' class='button button--primary'>Button</button>",
          codeCSS: `           .button { 
                      padding: 12px 25px; 
                      width: 140px; 
                      color: white; 
                      background-color: #1a5192; 
                      text-align: center; 
                      border-radius: 20px; 
                      border: none; 
                      font-size: 1rem; 
                      cursor: pointer;
                    }`,
          codeJS:
            "document.getElementById('button-js--primary').addEventListener('click', ()=> {console.log('Hello, world!')});",
          preview: "This is the preview",
          name: "Primary Button",
        },
        mainWithIcon: {},
        disabled: {},
      },
      secondary: {
        main: {
          name: "Secondary Button",
          codeHTML:
            "<button id='button-js--secondary' class='button button--secondary'>Button</button>",
          codeCSS: `           .button--secondary { 
                      padding: 12px 25px; 
                      width: 140px; 
                      color: white; 
                      background-color: white; 
                      text-align: center; 
                      border-radius: 20px; 
                      border: none; 
                      font-size: 1rem; 
                      cursor: pointer;
                      border: 3px solid #1a5192;
                      color: #1a5192;
                    }`,
          codeJS: `document.getElementById('button-js--secondary')
            .addEventListener('click', ()=> {console.log('Hello, world!')});`,
        },
        mainWithIcon: {},
        disabled: {},
      },
      tertiary: {
        main: {},
        mainWithIcon: {},
        disabled: {},
      },
      text: {
        main: {},
        mainWithIcon: {},
        disabled: {},
      },
    };
  },
  components: {
    CodeView,
    ButtonComponent,
    TempLeftNav,
  },
};
</script>
