<template>
  <button
    :id="'button-js' + '--' + `${type}`"
    class="button"
    :class="{
      'button--primary': type === 'primary',
      'button--secondary': type === 'secondary',
      'button--tertiary': type === 'tertiary',
      'button--text': type === 'text',
      icon,
      disabled,
    }"
  >
    {{ text }}
    <div v-if="icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-anchor"
      >
        <circle cx="12" cy="5" r="3"></circle>
        <line x1="12" y1="22" x2="12" y2="8"></line>
        <path d="M5 12H2a10 10 0 0 0 20 0h-3"></path>
      </svg>
    </div>
  </button>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: ["type", "subtype", "text", "icon", "disabled"],
};
</script>

<style>
.button {
  padding: 12px 25px;
  width: 140px;
  color: white;
  background-color: #ef1217;
  text-align: center;
  border-radius: 25px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  position: relative;
}
.icon {
  display: flex;
  justify-content: space-around;
}
.disabled {
  background-color: #ededed;
  color: #666666;
  cursor: unset;
}
.button--secondary {
  background-color: white;
  border: 3px solid #ef1217;
  color: #ef1217;
}
.button--secondary.disabled {
  border-color: #ededed;
  color: #666666;
}
.button--tertiary {
  background-color: #f2f2f2;
  color: #ef1217;
  border-radius: 4px;
}
.button--tertiary.disabled {
  cursor: unset;
}
.button--text {
  background-color: unset;
  color: #ef1217;
}
</style>
