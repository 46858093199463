<template>
  <div class="page-container">
    <div class="component-container" v-if="id === 'defaultModal'">
      <div
        id="modal-d"
        role="dialog"
        aria-labelledby="modal-d-heading"
        aria-describedby="modal-d-description"
      >
        <div class="modal-body rounded-20 white text-center gray-200">
          <div class="modal-content">
            <header>
              <div class="modal-title" id="modal-d-heading">
                Lorem ipsum
              </div>
            </header>

            <p id="modal-d-description">
              Lorem ipsum dolor sit amet sed do eiusmod tempor incididunt ut
              labore. Quis ipsum suspendisse ultrices gravida.
            </p>

            <div class="modal-button-container">
              <button
                class="primary iop-drk-blue rounded-full"
                aria-label="Open Application"
              >
                <div class="btn-txt font-white">Open Application</div>
                <div class="icon icon-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 14 16"
                    width="16"
                  >
                    <path
                      class="icon-svg"
                      d="M14,3.5V5H0V3.5A1.5,1.5,0,0,1,1.5,2H3V.5A.51.51,0,0,1,3.5,0h1A.51.51,0,0,1,5,
                            .5V2H9V.5A.51.51,0,0,1,9.5,0h1a.51.51,0,0,1,.5.5V2h1.5A1.5,1.5,0,0,1,14,3.5ZM0,6H14v8.5A1.5,1.5,0,0,1,12.5,
                            16H1.5A1.5,1.5,0,0,1,0,14.5ZM2,9.62a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,9.62V8.38A.38.38,0,0,0,3.62,
                            8H2.38A.38.38,0,0,0,2,8.38Zm0,4a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,13.62V12.38A.38.38,0,0,0,3.62,
                            12H2.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,9.62V8.38A.38.38,0,0,0,7.62,
                            8H6.38A.38.38,0,0,0,6,8.38Zm0,4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,13.62V12.38A.38.38,0,0,0,7.62,
                            12H6.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38h1.24A.38.38,0,0,0,12,9.62V8.38A.38.38,0,0,0,11.62,
                            8H10.38a.38.38,0,0,0-.38.38Zm0,4a.38.38,0,0,0,.38.38h1.24a.38.38,0,0,0,.38-.38V12.38a.38.38,0,0,
                            0-.38-.38H10.38a.38.38,0,0,0-.38.38Z"
                    ></path>
                  </svg>
                </div>
              </button>
            </div>
          </div>
          <a role="button" class="modal_close" aria-label="Close this window"
            >close</a
          >
        </div>
      </div>
    </div>
    <div
      class="component-container"
      v-else-if="id === 'modalForcedAction'"
    >
      <div
        id="modal-fa"
        role="dialog"
        aria-labelledby="modal-fa-heading"
        aria-describedby="modal-fa-description"
      >
        <div class="modal-body rounded-20 white text-center shadow" tabindex="-1">
          <div class="modal-content">
            <header>
              <div class="modal-title" id="modal-fa-heading">
                Lorem ipsum dolor sit
              </div>
            </header>

            <p id="modal-fa-description">
              Lorem ipsum dolor sit amet sed do eiusmod tempor incididunt ut
              labore. Quis ipsum suspendisse ultrices gravida.
            </p>

            <div class="modal-button-container">
              <button
                class="secondary border-iop-drk-blue border-thick rounded-full"
                aria-label="Sign out"
              >
                <div class="btn-txt font-iop-drk-blue">Sign out</div>
                <div class="icon icon-iop-drk-blue">
                  <svg
                    id="svg"
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                    viewBox="0 0 15.5 15.5"
                  >
                    <path
                      class="icon-svg"
                      d="M15.5,7.75A7.75,7.75,0,1,1,7.75,0,7.75,7.75,0,0,1,15.5,7.75Zm-6.29-1H3.5a.74.74,0,0,
                            0-.75.75V8a.74.74,0,0,0,.75.75H9.21L6.85,11a.75.75,0,0,0,0,1.07l.35.34a.74.74,0,0,0,1.06,0l4.14-4.14a.74.74,0,0,0,
                            0-1.06L8.24,3.07a.75.75,0,0,0-1.06,0l-.35.34a.76.76,0,0,0,0,1.08Z"
                    ></path>
                  </svg>
                </div>
              </button>

              <button
                class="primary iop-drk-blue rounded-full"
                aria-label="Open Application"
              >
                <div class="btn-txt font-white">Open Application</div>
                <div class="icon icon-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 14 16"
                    width="16"
                  >
                    <path
                      class="icon-svg"
                      d="M14,3.5V5H0V3.5A1.5,1.5,0,0,1,1.5,2H3V.5A.51.51,0,0,1,3.5,0h1A.51.51,0,0,1,5,
                            .5V2H9V.5A.51.51,0,0,1,9.5,0h1a.51.51,0,0,1,.5.5V2h1.5A1.5,1.5,0,0,1,14,3.5ZM0,6H14v8.5A1.5,1.5,0,0,1,12.5,
                            16H1.5A1.5,1.5,0,0,1,0,14.5ZM2,9.62a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,9.62V8.38A.38.38,0,0,0,3.62,
                            8H2.38A.38.38,0,0,0,2,8.38Zm0,4a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,13.62V12.38A.38.38,0,0,0,3.62,
                            12H2.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,9.62V8.38A.38.38,0,0,0,7.62,
                            8H6.38A.38.38,0,0,0,6,8.38Zm0,4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,13.62V12.38A.38.38,0,0,0,7.62,
                            12H6.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38h1.24A.38.38,0,0,0,12,9.62V8.38A.38.38,0,0,0,11.62,
                            8H10.38a.38.38,0,0,0-.38.38Zm0,4a.38.38,0,0,0,.38.38h1.24a.38.38,0,0,0,.38-.38V12.38a.38.38,0,0,
                            0-.38-.38H10.38a.38.38,0,0,0-.38.38Z"
                    ></path>
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="component-container"
      v-else-if="id === 'modalCircularIcon'"
    >
      <div
        id="modal-ci"
        role="dialog"
        aria-labelledby="modal-ci-heading"
        aria-describedby="modal-ci-description"
        aria-hidden="true"
        tabindex="0"
      >
        <div class="modal-body rounded-20 white text-center shadow">
          <div class="modal-img-container mb-60">
            <div class="modal-img-circle overlap-top">
              <img src="../../../assets/avatar.jpg" alt="" />
            </div>
          </div>

          <div class="modal-content">
            <header>
              <div class="modal-title" id="modal-ci-heading">
                Success, <br />New User Created
              </div>
            </header>

            <p id="modal-ci-description">
              Lorem ipsum dolor sit amet sed do eiusmod tempor incididunt ut
              labore. Quis ipsum suspendisse ultrices gravida.
            </p>

            <div class="modal-button-container">
              <button
                id=""
                class="secondary border-iop-drk-blue border-thick rounded-full"
                aria-label="Close Application"
              >
                <div class="btn-txt font-iop-drk-blue">Close Application</div>
                <div class="icon icon-iop-drk-blue">
                  <svg
                    id="svg"
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                    viewBox="0 0 15.5 15.5"
                  >
                    <path
                      class="icon-svg"
                      d="M15.5,7.75A7.75,7.75,0,1,1,7.75,0,7.75,7.75,0,0,1,15.5,7.75Zm-6.29-1H3.5a.74.74,0,0,
                                0-.75.75V8a.74.74,0,0,0,.75.75H9.21L6.85,11a.75.75,0,0,0,0,1.07l.35.34a.74.74,0,0,0,1.06,0l4.14-4.14a.74.74,0,0,0,
                                0-1.06L8.24,3.07a.75.75,0,0,0-1.06,0l-.35.34a.76.76,0,0,0,0,1.08Z"
                    ></path>
                  </svg>
                </div>
              </button>

              <button
                id=""
                class="primary iop-drk-blue rounded-full"
                aria-label="Open Application"
              >
                <div class="btn-txt font-white">Open Application</div>
                <div class="icon icon-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 14 16"
                    width="16"
                  >
                    <path
                      class="icon-svg"
                      d="M14,3.5V5H0V3.5A1.5,1.5,0,0,1,1.5,2H3V.5A.51.51,0,0,1,3.5,0h1A.51.51,0,0,1,5,
                                .5V2H9V.5A.51.51,0,0,1,9.5,0h1a.51.51,0,0,1,.5.5V2h1.5A1.5,1.5,0,0,1,14,3.5ZM0,6H14v8.5A1.5,1.5,0,0,1,12.5,
                                16H1.5A1.5,1.5,0,0,1,0,14.5ZM2,9.62a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,9.62V8.38A.38.38,0,0,0,3.62,
                                8H2.38A.38.38,0,0,0,2,8.38Zm0,4a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,13.62V12.38A.38.38,0,0,0,3.62,
                                12H2.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,9.62V8.38A.38.38,0,0,0,7.62,
                                8H6.38A.38.38,0,0,0,6,8.38Zm0,4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,13.62V12.38A.38.38,0,0,0,7.62,
                                12H6.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38h1.24A.38.38,0,0,0,12,9.62V8.38A.38.38,0,0,0,11.62,
                                8H10.38a.38.38,0,0,0-.38.38Zm0,4a.38.38,0,0,0,.38.38h1.24a.38.38,0,0,0,.38-.38V12.38a.38.38,0,0,
                                0-.38-.38H10.38a.38.38,0,0,0-.38.38Z"
                    ></path>
                  </svg>
                </div>
              </button>
            </div>
          </div>

          <a
            role="button"
            class="modal_close modalkill"
            aria-label="Close this window"
            >close</a
          >
        </div>
      </div>
    </div>
    <div
      class="component-container"
      v-else-if="id === 'modalHeaderImage'"
    >
      <div
        id="modal-fa"
        role="dialog"
        aria-labelledby="modal-fa-heading"
        aria-describedby="modal-fa-description"
      >
        <div class="modal-body rounded-20 white text-center border" tabindex="-1">
          <div
            class="modal-img-container rounded-top-20 h200"
            style="background: #f0f4f7;"
          >
            <img class="img-top" src="../../../assets/file-tower.jpg" alt="" />
          </div>

          <div class="modal-content">
            <header>
              <div class="modal-title" id="modal-fa-heading">
                Open modal with forced action
              </div>
            </header>

            <p id="modal-fa-description">
              Lorem ipsum dolor sit amet sed do eiusmod tempor incididunt ut
              labore. Quis ipsum suspendisse ultrices gravida.
            </p>

            <div class="modal-button-container">
              <button
                class="secondary border-iop-drk-blue border-thick rounded-full"
                aria-label="Sign out"
              >
                <div class="btn-txt font-iop-drk-blue">Sign out</div>
                <div class="icon icon-iop-drk-blue">
                  <svg
                    id="svg"
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                    viewBox="0 0 15.5 15.5"
                  >
                    <path
                      class="icon-svg"
                      d="M15.5,7.75A7.75,7.75,0,1,1,7.75,0,7.75,7.75,0,0,1,15.5,7.75Zm-6.29-1H3.5a.74.74,0,0,
                            0-.75.75V8a.74.74,0,0,0,.75.75H9.21L6.85,11a.75.75,0,0,0,0,1.07l.35.34a.74.74,0,0,0,1.06,0l4.14-4.14a.74.74,0,0,0,
                            0-1.06L8.24,3.07a.75.75,0,0,0-1.06,0l-.35.34a.76.76,0,0,0,0,1.08Z"
                    ></path>
                  </svg>
                </div>
              </button>

              <button
                class="primary iop-drk-blue rounded-full"
                aria-label="Open Application"
              >
                <div class="btn-txt font-white">Open Application</div>
                <div class="icon icon-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 14 16"
                    width="16"
                  >
                    <path
                      class="icon-svg"
                      d="M14,3.5V5H0V3.5A1.5,1.5,0,0,1,1.5,2H3V.5A.51.51,0,0,1,3.5,0h1A.51.51,0,0,1,5,
                            .5V2H9V.5A.51.51,0,0,1,9.5,0h1a.51.51,0,0,1,.5.5V2h1.5A1.5,1.5,0,0,1,14,3.5ZM0,6H14v8.5A1.5,1.5,0,0,1,12.5,
                            16H1.5A1.5,1.5,0,0,1,0,14.5ZM2,9.62a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,9.62V8.38A.38.38,0,0,0,3.62,
                            8H2.38A.38.38,0,0,0,2,8.38Zm0,4a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,13.62V12.38A.38.38,0,0,0,3.62,
                            12H2.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,9.62V8.38A.38.38,0,0,0,7.62,
                            8H6.38A.38.38,0,0,0,6,8.38Zm0,4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,13.62V12.38A.38.38,0,0,0,7.62,
                            12H6.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38h1.24A.38.38,0,0,0,12,9.62V8.38A.38.38,0,0,0,11.62,
                            8H10.38a.38.38,0,0,0-.38.38Zm0,4a.38.38,0,0,0,.38.38h1.24a.38.38,0,0,0,.38-.38V12.38a.38.38,0,0,
                            0-.38-.38H10.38a.38.38,0,0,0-.38.38Z"
                    ></path>
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalComponent",
  props: ["type", "id"],
  data: function() {
    return {};
  },
};
</script>

<style scoped>
#modal-ci .modal-body,
#modal-fa .modal-body,
#modal-d .modal-body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  background:#FFF;
  transition: opacity 0.25s ease-in-out;
}

.modal-body.shadow{ box-shadow: 0 0 85px -3px rgb(0 0 0 / 20%)!important; }


.modal-img-container {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.modal-img-circle {
  width: 132px;
  height: 132px;
  border-radius: 100%;
  overflow: hidden;
  background: transparent;
}
.modal-img-circle.overlap-top {
  position: absolute;
  top: -40px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;
}
.modal-title {
  line-height: 36px;
  font-size: 36px;
  padding: 0 50px;
}
.modal-content > p {
  margin-bottom: 0;
  padding: 0 50px;
}
.modal-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin-top: 30px;
  padding: 0 50px;
}

.modal_close {
  position: absolute;
  top: 20px;
  right: 30px;
}

@media screen and (max-width: 512px){
 .modal-button-container > button{
    width: 100% !important;
  }
  .modal-button-container{
    padding: 0 40px;
  }
}
</style>
