<template>
  <div>
    <div
      id="default-modal"
      class="modal-wrapper fade"
      role="dialog"
      aria-labelledby="modal-1-heading"
      aria-describedby="modal-1-description"
      v-if="demoToOpen === 'defaultDemoModal'"
    >
      <div class="modal-body gray-250 rounded-20 white text-center">
        <div class="modal-content">
          <header>
            <div class="modal-title" id="modal-1-heading">
              Lorem ipsum
            </div>
          </header>

          <p id="modal-1-description">
            Lorem ipsum dolor sit amet sed do eiusmod tempor incididunt ut
            labore. Quis ipsum suspendisse ultrices gravida.
          </p>

          <div class="modal-button-container">
            <button
              class="primary iop-drk-blue rounded-full"
              aria-label="Open Application"
            >
              <div class="btn-txt font-white">Open Application</div>
              <div class="icon icon-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 14 16"
                  width="16"
                >
                  <path
                    class="icon-svg"
                    d="M14,3.5V5H0V3.5A1.5,1.5,0,0,1,1.5,2H3V.5A.51.51,0,0,1,3.5,0h1A.51.51,0,0,1,5,
                            .5V2H9V.5A.51.51,0,0,1,9.5,0h1a.51.51,0,0,1,.5.5V2h1.5A1.5,1.5,0,0,1,14,3.5ZM0,6H14v8.5A1.5,1.5,0,0,1,12.5,
                            16H1.5A1.5,1.5,0,0,1,0,14.5ZM2,9.62a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,9.62V8.38A.38.38,0,0,0,3.62,
                            8H2.38A.38.38,0,0,0,2,8.38Zm0,4a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,13.62V12.38A.38.38,0,0,0,3.62,
                            12H2.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,9.62V8.38A.38.38,0,0,0,7.62,
                            8H6.38A.38.38,0,0,0,6,8.38Zm0,4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,13.62V12.38A.38.38,0,0,0,7.62,
                            12H6.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38h1.24A.38.38,0,0,0,12,9.62V8.38A.38.38,0,0,0,11.62,
                            8H10.38a.38.38,0,0,0-.38.38Zm0,4a.38.38,0,0,0,.38.38h1.24a.38.38,0,0,0,.38-.38V12.38a.38.38,0,0,
                            0-.38-.38H10.38a.38.38,0,0,0-.38.38Z"
                  ></path>
                </svg>
              </div>
            </button>
          </div>
        </div>
        <button
          @click="closeDemo()"
          role="button"
          class="modal_close pointer"
          aria-label="Close this window"
        >
          close
        </button>
      </div>
      <a @click="closeDemo()" class="modal-outside-trigger"></a>
    </div>
    <!--Modal 2-->
    <div
      id="modal-forced-action"
      class="modal-wrapper fade"
      role="dialog"
      aria-labelledby="modal-2-heading"
      aria-describedby="modal-2-description"
      v-if="demoToOpen === 'demoModalFA'"
    >
      <div class="modal-body modal-shadow rounded-20 white text-center" tabindex="-1">
        <div class="modal-content">
          <header>
            <div class="modal-title" id="modal-2-heading">
              Lorem ipsum dolor sit
            </div>
          </header>

          <p id="modal-2-description">
            Lorem ipsum dolor sit amet sed do eiusmod tempor incididunt ut
            labore. Quis ipsum suspendisse ultrices gravida.
          </p>

          <div class="modal-button-container">
            <button
              @click="closeDemo()"
              class="secondary border-iop-drk-blue border-thick rounded-full"
              aria-label="Sign out"
            >
              <div class="btn-txt font-iop-drk-blue">Sign out</div>
              <div class="icon icon-iop-drk-blue">
                <svg
                  id="svg"
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  viewBox="0 0 15.5 15.5"
                >
                  <path
                    class="icon-svg"
                    d="M15.5,7.75A7.75,7.75,0,1,1,7.75,0,7.75,7.75,0,0,1,15.5,7.75Zm-6.29-1H3.5a.74.74,0,0,
                            0-.75.75V8a.74.74,0,0,0,.75.75H9.21L6.85,11a.75.75,0,0,0,0,1.07l.35.34a.74.74,0,0,0,1.06,0l4.14-4.14a.74.74,0,0,0,
                            0-1.06L8.24,3.07a.75.75,0,0,0-1.06,0l-.35.34a.76.76,0,0,0,0,1.08Z"
                  ></path>
                </svg>
              </div>
            </button>

            <button
              class="primary iop-drk-blue rounded-full"
              aria-label="Open Application"
            >
              <div class="btn-txt font-white">Open Application</div>
              <div class="icon icon-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 14 16"
                  width="16"
                >
                  <path
                    class="icon-svg"
                    d="M14,3.5V5H0V3.5A1.5,1.5,0,0,1,1.5,2H3V.5A.51.51,0,0,1,3.5,0h1A.51.51,0,0,1,5,
                            .5V2H9V.5A.51.51,0,0,1,9.5,0h1a.51.51,0,0,1,.5.5V2h1.5A1.5,1.5,0,0,1,14,3.5ZM0,6H14v8.5A1.5,1.5,0,0,1,12.5,
                            16H1.5A1.5,1.5,0,0,1,0,14.5ZM2,9.62a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,9.62V8.38A.38.38,0,0,0,3.62,
                            8H2.38A.38.38,0,0,0,2,8.38Zm0,4a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,13.62V12.38A.38.38,0,0,0,3.62,
                            12H2.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,9.62V8.38A.38.38,0,0,0,7.62,
                            8H6.38A.38.38,0,0,0,6,8.38Zm0,4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,13.62V12.38A.38.38,0,0,0,7.62,
                            12H6.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38h1.24A.38.38,0,0,0,12,9.62V8.38A.38.38,0,0,0,11.62,
                            8H10.38a.38.38,0,0,0-.38.38Zm0,4a.38.38,0,0,0,.38.38h1.24a.38.38,0,0,0,.38-.38V12.38a.38.38,0,0,
                            0-.38-.38H10.38a.38.38,0,0,0-.38.38Z"
                  ></path>
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--Modal 3-->
    <div
      id="modal-circular-image"
      class="modal-wrapper fade"
      role="dialog"
      aria-labelledby="modal-3-heading"
      aria-describedby="modal-3-description"
      aria-hidden="true"
      tabindex="0"
      v-if="demoToOpen === 'demoModalCI'"
    >
      <div class="modal-body modal-shadow rounded-20 white text-center">
        <div class="modal-img-container mb-60">
          <div class="modal-img-circle overlap-top">
            <img src="../../../assets/avatar.jpg" alt="" />
          </div>
        </div>

        <div class="modal-content">
          <header>
            <div class="modal-title" id="modal-3-heading">
              Success, <br />New User Created
            </div>
          </header>

          <p id="modal-3-description">
            Lorem ipsum dolor sit amet sed do eiusmod tempor incididunt ut
            labore. Quis ipsum suspendisse ultrices gravida.
          </p>

          <div class="modal-button-container">
            <button
              @click="closeDemo()"
              id=""
              class="secondary border-iop-drk-blue border-thick rounded-full"
              aria-label="Close Application"
            >
              <div class="btn-txt font-iop-drk-blue">Close Application</div>
              <div class="icon icon-iop-drk-blue">
                <svg
                  id="svg"
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  viewBox="0 0 15.5 15.5"
                >
                  <path
                    class="icon-svg"
                    d="M15.5,7.75A7.75,7.75,0,1,1,7.75,0,7.75,7.75,0,0,1,15.5,7.75Zm-6.29-1H3.5a.74.74,0,0,
                                0-.75.75V8a.74.74,0,0,0,.75.75H9.21L6.85,11a.75.75,0,0,0,0,1.07l.35.34a.74.74,0,0,0,1.06,0l4.14-4.14a.74.74,0,0,0,
                                0-1.06L8.24,3.07a.75.75,0,0,0-1.06,0l-.35.34a.76.76,0,0,0,0,1.08Z"
                  ></path>
                </svg>
              </div>
            </button>

            <button
              id=""
              class="primary iop-drk-blue rounded-full"
              aria-label="Open Application"
            >
              <div class="btn-txt font-white">Open Application</div>
              <div class="icon icon-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 14 16"
                  width="16"
                >
                  <path
                    class="icon-svg"
                    d="M14,3.5V5H0V3.5A1.5,1.5,0,0,1,1.5,2H3V.5A.51.51,0,0,1,3.5,0h1A.51.51,0,0,1,5,
                                .5V2H9V.5A.51.51,0,0,1,9.5,0h1a.51.51,0,0,1,.5.5V2h1.5A1.5,1.5,0,0,1,14,3.5ZM0,6H14v8.5A1.5,1.5,0,0,1,12.5,
                                16H1.5A1.5,1.5,0,0,1,0,14.5ZM2,9.62a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,9.62V8.38A.38.38,0,0,0,3.62,
                                8H2.38A.38.38,0,0,0,2,8.38Zm0,4a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,13.62V12.38A.38.38,0,0,0,3.62,
                                12H2.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,9.62V8.38A.38.38,0,0,0,7.62,
                                8H6.38A.38.38,0,0,0,6,8.38Zm0,4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,13.62V12.38A.38.38,0,0,0,7.62,
                                12H6.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38h1.24A.38.38,0,0,0,12,9.62V8.38A.38.38,0,0,0,11.62,
                                8H10.38a.38.38,0,0,0-.38.38Zm0,4a.38.38,0,0,0,.38.38h1.24a.38.38,0,0,0,.38-.38V12.38a.38.38,0,0,
                                0-.38-.38H10.38a.38.38,0,0,0-.38.38Z"
                  ></path>
                </svg>
              </div>
            </button>
          </div>
        </div>

        <button
          @click="closeDemo()"
          role="button"
          class="modal_close modalkill pointer"
          aria-label="Close this window"
        >
          close
        </button>
      </div>
      <a @click="closeDemo()" class="modal-outside-trigger"></a>
    </div>

    <!--Modal 4-->
    <div
      id="modal-header-img"
      class="modal-wrapper fade"
      role="dialog"
      aria-labelledby="modal-3-heading"
      aria-describedby="modal-3-description"
      aria-hidden="true"
      tabindex="0"
      v-if="demoToOpen === 'demoModalHI'"
    >
      <div class="modal-body border rounded-20 white text-center" tabindex="-1">
        <div
          class="modal-img-container rounded-top-20 h200"
          style="background: #f0f4f7;"
        >
          <img class="img-top" src="../../../assets/file-tower.jpg" alt="" />
        </div>

        <div class="modal-content">
          <header>
            <div class="modal-title" id="modal-3-heading">
              Open modal with forced action
            </div>
          </header>

          <p id="modal-3-description">
            Lorem ipsum dolor sit amet sed do eiusmod tempor incididunt ut
            labore. Quis ipsum suspendisse ultrices gravida.
          </p>

          <div class="modal-button-container">
            <button
              @click="closeDemo()"
              class="secondary border-iop-drk-blue border-thick rounded-full"
              aria-label="Sign out"
            >
              <div class="btn-txt font-iop-drk-blue">Sign out</div>
              <div class="icon icon-iop-drk-blue">
                <svg
                  id="svg"
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  viewBox="0 0 15.5 15.5"
                >
                  <path
                    class="icon-svg"
                    d="M15.5,7.75A7.75,7.75,0,1,1,7.75,0,7.75,7.75,0,0,1,15.5,7.75Zm-6.29-1H3.5a.74.74,0,0,
                            0-.75.75V8a.74.74,0,0,0,.75.75H9.21L6.85,11a.75.75,0,0,0,0,1.07l.35.34a.74.74,0,0,0,1.06,0l4.14-4.14a.74.74,0,0,0,
                            0-1.06L8.24,3.07a.75.75,0,0,0-1.06,0l-.35.34a.76.76,0,0,0,0,1.08Z"
                  ></path>
                </svg>
              </div>
            </button>

            <button
              class="primary iop-drk-blue rounded-full"
              aria-label="Open Application"
            >
              <div class="btn-txt font-white">Open Application</div>
              <div class="icon icon-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 14 16"
                  width="16"
                >
                  <path
                    class="icon-svg"
                    d="M14,3.5V5H0V3.5A1.5,1.5,0,0,1,1.5,2H3V.5A.51.51,0,0,1,3.5,0h1A.51.51,0,0,1,5,
                            .5V2H9V.5A.51.51,0,0,1,9.5,0h1a.51.51,0,0,1,.5.5V2h1.5A1.5,1.5,0,0,1,14,3.5ZM0,6H14v8.5A1.5,1.5,0,0,1,12.5,
                            16H1.5A1.5,1.5,0,0,1,0,14.5ZM2,9.62a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,9.62V8.38A.38.38,0,0,0,3.62,
                            8H2.38A.38.38,0,0,0,2,8.38Zm0,4a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,13.62V12.38A.38.38,0,0,0,3.62,
                            12H2.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,9.62V8.38A.38.38,0,0,0,7.62,
                            8H6.38A.38.38,0,0,0,6,8.38Zm0,4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,13.62V12.38A.38.38,0,0,0,7.62,
                            12H6.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38h1.24A.38.38,0,0,0,12,9.62V8.38A.38.38,0,0,0,11.62,
                            8H10.38a.38.38,0,0,0-.38.38Zm0,4a.38.38,0,0,0,.38.38h1.24a.38.38,0,0,0,.38-.38V12.38a.38.38,0,0,
                            0-.38-.38H10.38a.38.38,0,0,0-.38.38Z"
                  ></path>
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalDemoComponent",
  props: ["demoToOpen"],
  data: function() {
    return {};
  },
  methods: {
    closeDemo: function() {
      var demoClosed = false;
      this.$emit("demoCloseValue", demoClosed);
    },
  },
};
</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255 255 255 / 0.75);
  transition: opacity 0.25s ease-in-out;
  z-index: 2;
}
#default-modal .modal-body,
#modal-forced-action .modal-body,
#modal-circular-image .modal-body,
#modal-header-img .modal-body,
#modal-1 .modal-body,
#modal-2 .modal-body,
#modal-3 .modal-body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  margin: 0 20px;

  background: white;
  /* box-shadow: 0 0 85px -3px rgb(0 0 0 / 20%); */
  transition: opacity 0.25s ease-in-out;
}

.modal-img-container {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.modal-img-circle {
  width: 132px;
  height: 132px;
  border-radius: 100%;
  overflow: hidden;
  background: transparent;
}
.modal-img-circle.overlap-top {
  position: absolute;
  top: -40px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;
}
.modal-title {
  line-height: 36px;
  font-size: 36px;
  padding: 0 50px;
}
.modal-content > p {
  margin-bottom: 0;
  padding: 0 50px;
}
.modal-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin-top: 30px;
  padding: 0 50px;
}

.modal_close {
  position: absolute;
  top: 20px;
  right: 30px;
}
.modal-outside-trigger {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: default;
  z-index: -1;
}
.modal-body.modal-shadow{
    box-shadow: 0 0 85px -3px rgb(0 0 0 / 20%);
}

@media screen and (max-width: 512){
 .modal-content > .modal-button-container > button{
    width: 100% !important;
  }
}
</style>
