<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Modal</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              A modal is an object that overlays the center of the page and
              focuses the user’s attention on a single tas
            </p>
          </div>
        </section>
        <div class="divider"></div>
        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1000">
            
            <section id="general-guidelines">
              <div class="content-band">
                <h2>General Guidelines</h2>
                <ul class="bullet-red">
                  <li>
                    A modal should only be used when user input is
                    <b>critical</b>, such that the user cannot continue without
                    interacting with the modal. Examples include:
                  </li>
                  <ul class="sublist">
                    <li>
                      Confirming an action or task that can’t be undone, such as
                      canceling out of a process or flow, or the deletion of
                      data.
                    </li>
                    <li>
                      Entering a multi-factor authentication or password reset
                      PIN.
                    </li>
                  </ul>
                  <li>
                    Modals should not be used for error, success, or warning
                    messages. When related to forms, these messages should
                    display inline. If a page level message, use an alert
                    instead.
                  </li>
                  <li>
                    Users should trigger modals, modals should not automatically
                    display. The exception is if a user is about to time out of
                    a process or flow.
                  </li>
                  <li>
                    A modal should not be used for nonessential information that
                    is not related to the current user workflow. For example, an
                    email subscription pop-up.
                  </li>
                </ul>
              </div>
            </section>
            <div class="divider"></div>
            
            <section id="modal-accessibility">
              <div class="content-band">
                <h2>Modal Accessibility</h2>
                <ul class="bullet-red">
                  <li>
                    Use
                    <span class="red-100 plr-5 rounded-4 font-red"
                      >aria-labelledby=”id”</span
                    >
                    on
                    <span class="red-100 plr-5 rounded-4 font-red"
                      >.modal-wrapper</span
                    >
                    to associate a title to the modal that is read when the
                    modal opens. The id should belong to the header within the
                    modal.
                  </li>
                  <li>
                    Use
                    <span class="red-100 plr-5 rounded-4 font-red"
                      >aria-describedby=”id”</span
                    >
                    on
                    <span class="red-100 plr-5 rounded-4 font-red"
                      >.modal-wrapper</span
                    >
                    to associate descriptive text to the modal that is read when
                    the modal opens. The id should belong to a paragraph or a
                    brief piece of content within the modal.
                  </li>
                  <li>
                    For modals that don’t require forced action, include the
                    close button at the end of the modal code. CSS will display
                    the close button at the top right of the modal window, but
                    placing the close button at the bottom of the modal will
                    prevent some screen readers from reading the close button
                    first.
                  </li>
                </ul>
              </div>
            </section>

            <div class="divider"></div>
           
            <section id="modal-usability">
              <div class="content-band">
                <h2>Modal Usability</h2>
                <ul class="bullet-red">
                  <li>
                    <b>Use clear header and button text.</b> The header should
                    clearly state what’s happening or what action the modal is
                    prompting the user to do. The button text should indicate
                    what will happen when selected and avoid ambiguity. For
                    example, if a modal asks “Do you want to cancel?” the button
                    options should not be “yes” and “cancel” — instead use
                    something like “Yes, cancel” and “No, don’t cancel.”
                  </li>
                  <li>
                    <b>Limit in-modal interactions.</b> Avoid using components
                    other than buttons inside the modal. Components such as
                    accordions and form fields often don’t scale well for mobile
                    users and they can easily lose context that they’re viewing
                    a modal. If you need to include links that navigate away
                    from a modal to another webpage, refer to our link guidance
                    about how to handle external links.
                  </li>
                </ul>
              </div>
            </section>
            <div class="divider"></div>
           
            <section id="import-component">
              <div class="content-band">
                <h2>Import the Component</h2>
                <ImportLinkComponent id="modal-css" />
                <ImportLinkComponent id="modal-js" />
              </div>
            </section>
           
            <div class="divider"></div>

            <!-- Default Modal -->
            <section id="default-modal">
              <div class="content-band">
                <div class="flex align-center max-w800 width-full">
                  <div>Default Modal</div>
                  <div id="demo-button" class="demo-btn"
                    
                    @click="openDemo('default')">
                      <div>Demo</div>
                  </div>
                </div>
                <CodeView component="modal" type="default-modal">
                  <ModalComponent id="defaultModal" />
                </CodeView>
              </div>
              <ModalDemoComponent
                v-show="showDefaultModal"
                demoToOpen="defaultDemoModal"
                v-on:demoCloseValue="updatedCloseValue"
              />
            </section>

            <!-- Modal with forced action -->
            <section id="modal-with-forced-action">
              <div class="content-band">
                <div class="flex align-center">
                  <div>Modal With Forced Action</div>
                  <div id="demo-button" class="demo-btn"
                    @click="openDemo('modalForcedAction')"
                  >
                    <div>Demo</div>
                  </div>
                </div>
                <CodeView component="modal" type="modal-forced-action">
                  <ModalComponent id="modalForcedAction" />
                </CodeView>
                <ModalDemoComponent
                  v-show="showModalForcedAction"
                  demoToOpen="demoModalFA"
                  v-on:demoCloseValue="updatedCloseValue"
                />
              </div>
            </section>

            <!-- Modal with circular image -->
            <section id="modal-with-circular-image">
              <div class="content-band">
                <div class="flex align-center">
                  <div>Modal With Circular Image</div>
                  <div id="demo-button" class="demo-btn"
                    @click="openDemo('modalCircularImage')"
                  >
                    <div>Demo</div>
                  </div>
                </div>
                <CodeView component="modal" type="modal-circular-image">
                  <ModalComponent id="modalCircularIcon" />
                </CodeView>
                <ModalDemoComponent
                  v-show="showModalCircularImage"
                  demoToOpen="demoModalCI"
                  v-on:demoCloseValue="updatedCloseValue"
                />
              </div>
              <!-- Modal - no image open-->
            </section>

            <!-- Modal with HeaderImage-->
            <section id="modal-with-header-image">
              <div class="content-band">
                <div class="flex align-center">
                  <div>Modal With Header Image</div>
                  <div
                    id="demo-button"
                    class="demo-btn"
                    @click="openDemo('modalHeaderImage')"
                  >
                    <div>Demo</div>
                  </div>
                </div>
                <CodeView component="modal" type="modal-header-image">
                  <ModalComponent id="modalHeaderImage" />
                </CodeView>
                <ModalDemoComponent
                  v-show="showModalHeaderImage"
                  demoToOpen="demoModalHI"
                  v-on:demoCloseValue="updatedCloseValue"
                />
              </div>
              <!-- Modal with forced action open-->
            </section>
          </div>
          <StickyTOC />
        </div>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
import StickyTOC from "../../StickyTOC";
import ModalComponent from "../Modal/ModalComponent.vue";
import ModalDemoComponent from "../Modal/ModalDemoComponent";
import CodeView from "../../CodeView";
import TempLeftNav from "../../TempLeftNav";
import SubFooter from "../../../components/SubFooter";
import ImportLinkComponent from "../../../components/ImportLinkComponent";
export default {
  name: "Modal",
  components: {
    ModalComponent,
    StickyTOC,
    CodeView,
    TempLeftNav,
    SubFooter,
    ImportLinkComponent,
    ModalDemoComponent,
  },
  data: function() {
    return {
      showDefaultModal: false,
      showModalForcedAction: false,
      showModalCircularImage: false,
      showModalHeaderImage: false,
    };
  },
  methods: {
    openDemo: function(modalName) {
      var selectedModal;
      if (modalName === "default") {
        this.showDefaultModal = true;
        selectedModal = document.getElementById("default-modal");
        this.trapFocus(selectedModal);
      } else if (modalName === "modalForcedAction") {
        this.showModalForcedAction = true;
        selectedModal = document.getElementById("modal-forced-action");
        this.trapFocus(selectedModal);
      } else if (modalName === "modalCircularImage") {
        this.showModalCircularImage = true;
        selectedModal = document.getElementById("modal-circular-image");
        this.trapFocus(selectedModal);
      } else if (modalName === "modalHeaderImage") {
        this.showModalHeaderImage = true;
        selectedModal = document.getElementById("modal-header-img");
        this.trapFocus(selectedModal);
      }
    },
    updatedCloseValue: function(updatedValue) {
      this.showDefaultModal = updatedValue;
      this.showModalForcedAction = updatedValue;
      this.showModalCircularImage = updatedValue;
      this.showModalHeaderImage = updatedValue;
    },
    trapFocus: function(modal) {
      var modalName = modal;
      const focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
      const firstFocusableElement = modalName.querySelectorAll(
        focusableElements
      )[0];
      const focusableContent = modalName.querySelectorAll(focusableElements);
      const lastFocusableElement =
        focusableContent[focusableContent.length - 1];

      document.addEventListener("keydown", function(e) {
        let isTabPressed = e.key === "Tab" || e.keyCode === 9;

        if (!isTabPressed) {
          return;
        }

        if (e.shiftKey) {
          // if shift key pressed for shift + tab combination
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus(); // add focus for the last focusable element
            e.preventDefault();
          }
        } else {
          // if tab key is pressed
          if (document.activeElement === lastFocusableElement) {
            // if focused has reached to last focusable element then focus first focusable element after pressing tab
            firstFocusableElement.focus(); // add focus for the first focusable element
            e.preventDefault();
          }
        }
      });

      firstFocusableElement.focus();
    },
  },
};
</script>
