var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"button",class:{
    'button--primary': _vm.type === 'primary',
    'button--secondary': _vm.type === 'secondary',
    'button--tertiary': _vm.type === 'tertiary',
    'button--text': _vm.type === 'text',
    icon: _vm.icon,
    disabled: _vm.disabled,
  },attrs:{"id":'button-js' + '--' + "" + _vm.type}},[_vm._v(" "+_vm._s(_vm.text)+" "),(_vm.icon)?_c('div',[_c('svg',{staticClass:"feather feather-anchor",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('circle',{attrs:{"cx":"12","cy":"5","r":"3"}}),_c('line',{attrs:{"x1":"12","y1":"22","x2":"12","y2":"8"}}),_c('path',{attrs:{"d":"M5 12H2a10 10 0 0 0 20 0h-3"}})])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }